import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {
    accountSlice,
    alertSlice,
    authSlice,
    changePasswordSlice,
    initLogout,
    loaderSlice,
    loginSlice,
    sagaSlice,
} from 'marine-panel-common-web';
import {createReduxHistoryContext} from 'redux-first-history';
import paymentsPageSlice from './paymentsPageSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    account: accountSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    saga: sagaSlice,
    paymentsPage: paymentsPageSlice,
    router: routerReducer,
    loader: loaderSlice,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, {type: undefined});
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
