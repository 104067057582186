import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiResponseViewObject, IReservationPayment} from 'marine-panel-common-web';
import {initApiCall} from '../../utils/reduxHelpers';

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface IBaseDictionaryState {
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
}

export interface IChangeIsLoading {
    isLoading: boolean;
}

export interface IChangeIsInitialized {
    isInitialized: boolean;
}

export interface ISetError {
    error: string | null;
}

export interface IChangePagePagination {
    pagination: IPaginationParams;
}

export interface ISetPageMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IPaymentsState extends IBaseDictionaryState {
    payments: IReservationPayment[] | null;
    paymentsMetadata: IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
}

export interface ISetPayments {
    readonly payments: IReservationPayment[] | null;
}

const initialState: IPaymentsState = {
    payments: null,
    paymentsMetadata: null,
    pagination: {
        page: 1,
        itemsPerPage: 10,
    },
    isLoading: false,
    isInitialized: false,
    error: null,
};

const paymentsSlice = createSlice({
    name: 'paymentsPage',
    initialState: initialState,
    reducers: {
        changePayments: {
            reducer: (state: IPaymentsState, action: PayloadAction<ISetPayments>) => {
                return {
                    ...state,
                    payments: action.payload.payments,
                };
            },
            prepare(payments: IReservationPayment[] | null) {
                return {
                    payload: {payments: payments},
                };
            },
        },
        changeIsPaymentsPageLoading: {
            reducer: (state: IPaymentsState, action: PayloadAction<IChangeIsLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isLoading: isLoading},
                };
            },
        },
        changeIsPaymentsPageInitialized: {
            reducer: (state: IPaymentsState, action: PayloadAction<IChangeIsInitialized>) => {
                return {
                    ...state,
                    isInitialized: action.payload.isInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isInitialized: isInitialized},
                };
            },
        },
        setPaymentsPageError: {
            reducer: (state: IPaymentsState, action: PayloadAction<ISetError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        setPaymentsPageMetadata: {
            reducer: (state: IPaymentsState, action: PayloadAction<ISetPageMetadata>) => {
                return {
                    ...state,
                    paymentsMetadata: action.payload.metadata,
                };
            },
            prepare(metadata: IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        metadata: metadata,
                    },
                };
            },
        },
        changePaymentsPagePagination: {
            reducer: (state: IPaymentsState, action: PayloadAction<IChangePagePagination>) => {
                return {
                    ...state,
                    isLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        fetchPayments: (state: IPaymentsState) => initApiCall(state),
        resetToInitialPaymentsPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changePayments,
    changeIsPaymentsPageLoading,
    changeIsPaymentsPageInitialized,
    setPaymentsPageError,
    setPaymentsPageMetadata,
    changePaymentsPagePagination,
    fetchPayments,
    resetToInitialPaymentsPageState,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
