import {IFormConfig, InputType, FormControlType, ValidationRules, FormButtonType} from 'marine-panel-common-web';

export const profileFormConfig = (): IFormConfig => ({
    controlType: 'form',
    class: 'default-form profile',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: 'group',
            key: 'skipper_email',
            class: 'row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_EMAIL, params: {}}],
                    placeholder: 'profile.changeProfile.form.placeholders.email',
                    isLabelHidden: false,
                    label: 'profile.changeProfile.form.labels.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12 form-control',
                    disabled: true,
                },
            },
        },
        {
            controlType: 'group',
            key: 'skipper_name',
            class: 'row',
            controls: {
                firstName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'profile.changeProfile.form.placeholders.firstName',
                    label: 'profile.changeProfile.form.labels.firstName',
                    hostClass: 'col-xl-12 form-control',
                    type: InputType.TEXT,
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'profile.changeProfile.form.placeholders.lastName',
                    label: 'profile.changeProfile.form.labels.lastName',
                    hostClass: 'col-xl-12 form-control',
                    type: InputType.TEXT,
                },
            },
        },
        {
            controlType: 'group',
            key: 'phone',
            class: 'row',
            controls: {
                phone: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.MIN_LENGTH, params: {length: 9}}],
                    placeholder: 'profile.changeProfile.form.placeholders.phone',
                    isLabelHidden: false,
                    label: 'profile.changeProfile.form.labels.phone',
                    type: InputType.TEL,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            key: 'submit',
            controlType: 'group',
            class: 'row justify-content-end',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-create',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 mt-1 align-items-end w-auto',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.saveChanges',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
                // discard: {
                //     controlType: 'control',
                //     formControlType: FormControlType.BUTTON,
                //     buttonType: FormButtonType.SUBMIT,
                //     inputStyles: 'btn-outline-secondary',
                //     defaultContainerStyles: '',
                //     hostClass: 'mb-0 mt-1 align-items-end w-auto',
                //     containerStyles: '',
                //     defaultValue: null,
                //     isLabelHidden: true,
                //     btnText: 'buttons.discard',
                //     buttonDisabled: false,
                // },
            },
        },
    ],
});
