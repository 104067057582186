const pl = {
    app: {
        title: 'Skipper Panel',
    },
    administration: {},
    auth: {
        authFooter: {
            srTitle: 'Stopka strony zawierająca dane kontaktowe, nawigację po stronie i zapisy do newslettera',
            addressColumn: {
                title: 'Educat Sp. z o.o.',
                address1: 'ul. Żurawia 71',
                address2: '5-540 Białystok, Polska',
                nip: 'NIP 123445677',
                regon: 'REGON  12',
            },
            contactColumn: {
                title: 'Kontakt',
                correspondenceAddressTitle: 'Adres korespondencyjny:',
                address1: 'Leonida Teligi 5/8 ',
                address2: '02-777 Warszawa',
            },
            linksColumn: {
                title: 'MarineHub',
                aboutUs: 'O nas',
                career: 'Kariera',
                cooperation: 'Współpraca',
                contact: 'Kontakt',
                policies: 'Regulamin i polityka prywatności',
                safePayments: 'Bezpieczne płatności',
            },
            newsletterColumn: {
                title: 'Bądź na bieżąco',
                subscribeInfo: 'Subskrybuj nasz newsletter',
                newsletterLabelSR: 'Wpisz swój adres email aby otrzymac newsletter',
                newsletterSubmitButtonSR: 'Przycisk zatwierdzania formularza subskrypcji Newslettera',
            },
            copyright: 'MarineHub {{date}} © Wszystkie prawa zastrzeżone.',
            socialIcons: {
                facebook: 'Link prowadzący do profilu na Facebooku',
                instagram: 'Link prowadzący do profilu na Instagramie',
                linkedin: 'Link prowadzący do profilu na Linkedin',
                youtube: 'Link prowadzący do kanalu Youtube',
            },
        },
        formControls: {
            placeholder: 'Type',
            email: 'Email',
            password: 'Password',
            newPassword: 'New password',
            confirmPassword: 'Confirm Password',
            repeatPassword: 'Repeat password',
            placeholderConfirmPassword: 'Confirm Password',
            registrationTermsAgreement: ``,
            phone: 'Phone',
            firstName: 'First name',
            lastName: 'Last name',
            registrationTerms: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr.`,
            placeholders: {
                email: 'Email',
                password: 'Password',
                repeatPassword: 'Repeat password',
            },
        },
        login: {
            title: 'Login',
            subtitle: '',
            haveAccount: 'New on our platform? ',
            register: 'Create an account.',
            resetPassword: 'Forgot password?',
            loginWith: {
                title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zaloguj się przez Facebook',
                google: 'Zaloguj się przez Google',
            },
        },
        resetPassword: {
            title: 'Reset password',
            subtitle: 'Please provide an email used during registration process. Further instructions will be sent to your email.',
        },
        newPassword: {
            title: 'Change password',
            subtitle: 'Enter a new password',
        },
        alert: {
            loginSuccess: 'Login successful.',
            loginFailed: 'Incorrect login or password. Please try again.',
            logout: 'You have been loged out.',
            registrationSuccess:
                'Registration data was successfully processed. Confirm registration by clicking on the link provided in the email.',
            confirmRegistrationSuccess: 'Your email has been confirmed. You can now login to the application.',
            sendResetPasswordMailSuccess: 'Please check your email for password reset link.',
            confirmResetPasswordSuccess: 'Your password has been changed. You can log in to the panel.',
            authenticationError: 'You must be logged in to access the data.',
        },
    },
    settings: {
        title: 'Settings',
        changePassword: {
            title: 'Profile Details',
            form: {
                labels: {
                    currentPassword: 'Current password',
                    newPassword: 'New password',
                    confirmPassword: 'Confirm new password',
                },
                placeholders: {
                    currentPassword: 'Enter current password',
                    newPassword: 'Enter new password',
                    confirmPassword: 'Confirm your new password',
                },
            },
            passwordRequirements: {
                title: 'Password requirements:',
                length: 'Minimum 8 characters long - the more, the better',
                uppercase: 'At least one lowercase character',
                characters: 'At least one number, symbol, or whitespace character',
            },
        },
        privacyPolicyTitle: 'Privacy Policy',
        privacyPolicyMessage: 'Privacy Policy',
        readLink: 'READ',
    },
    modal: {
        createBerth: {
            title: 'Create New Berth',
            inputs: {
                nameBerth: 'Name your Berth',
                nameBerthSub: 'It will be displayed to the Skippers',
                setMarina: 'Set your Marina',
                setMarinaSub: 'Find your Berth Marina. It will setup the map below. You can also do it later.',
            },
        },
    },
    buttons: {
        add: 'Add',
        addNew: 'Add new',
        upload: 'Upload',
        more: 'More',
        login: 'Login',
        edit: 'Edit',
        saveChanges: 'Save changes',
        discard: 'Discard',
        apply: 'Apply',
        buy: 'Buy',
        readLess: 'Read less',
        readMore: 'Read more',
        close: 'Close',
        join: 'Join',
        showAll: 'Show all',
        next: 'Next',
        previous: 'Previous',
        submit: 'Submit',
        change: 'Change',
        remove: 'Remove',
        save: 'Save',
        resetPassword: 'Reset password',
        setNewPassword: 'Set new password',
        register: 'Register',
        backToLogin: 'Back to login',
        createBerth: '+ Create Berth',
        createReservation: '+ Reservation',
        updateReservation: 'Update Reservation',
        cancel: 'Cancel',
        create: 'Create',
        showBerthInfo: 'Show Berth info',
        showLastPosition: 'Show last position',
        confirm: 'Confirm',
    },
    dateRangePicker: {
        config: {
            previousMonth: 'Previous month',
            lastWeek: 'Last week',
            today: 'Today',
            currentMonth: 'Current month',
        },
    },
    formValidation: {
        errors: {
            minLength: 'The value is too short',
            isEmailValid: 'Email is not valid',
            isRequired: 'Field is required',
            isCheckedTrue: 'Field should be selected',
            isTrue: 'Field should be selected',
            isPasswordSame: 'Passwords cannot be different',
            isStartDateValid: 'Please select future date',
            isStartDateGreaterOrEvenValid: 'Please select future date',
            isEndDateValid: 'Data powrotu musi być po dacie wylotu',
            isNumber: 'Provided value should be a number',
        },
    },
    footer: {
        copyright: `MarineHunter {{date}} © All right reserved`,
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony',
    },
    menuItems: {
        operators: 'Operators',
        marinas: 'Marinas',
        articles: 'Articles',
        inbox: 'Inbox',
        reports: 'Reports',
        administration: 'Administration',
        settings: 'Settings',
        logout: 'Logout',
    },
    alerts: {
        warning: 'Warning!',
        error: 'Error!',
        success: 'Success!',
        info: 'Info',
        authError: 'The username or password you entered is incorrect. Please try again',
    },
    account: {
        changeAccountDataSuccess: 'Dane konta zostały zmienione.',
        changeLocaleSuccess: 'Język został zmieniony.',
        changePasswordSuccess: 'Hasło zostało zmienione.',
        imageAddSuccess: 'Obraz został dodany.',
    },
};

export default pl;
