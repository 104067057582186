import React, {useEffect, useState} from 'react';
import {
    accountSelector,
    Form,
    FormControlChangeType,
    IFormConfig,
    accountIsLoadingSelector,
    Loader,
    LoaderType,
    SkipperFullInfo,
    OperatorFullInfo,
    updateAccount,
    ProfileSettingsModalForm,
} from 'marine-panel-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {profileFormConfig} from './profileFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';

interface IConnectedProfileDataProps {
    readonly account: SkipperFullInfo | OperatorFullInfo;
    readonly isLoading: boolean;
    readonly updateAccount: typeof updateAccount;
}

interface IProfileDataProps extends IConnectedProfileDataProps {}

const ProfileData: React.FC<IProfileDataProps> = ({account, isLoading, updateAccount}) => {
    const [value, setValue] = useState<{[key: string]: any} | null>(null),
        [formConfig, setFormConfig] = useState<IFormConfig | null>(null),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    useEffect(() => {
        setBillingFormConfig();
    }, []);

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName && data.value) {
                        setValue(data.value);
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    function submitBillingInfoForm() {
        if (value === null) {
            return;
        }

        const skipperAccount = (account as SkipperFullInfo).account,
            accountId = skipperAccount.id,
            payload: ProfileSettingsModalForm = {
                firstName: value.firstName ? value.firstName : skipperAccount.firstName,
                lastName: value.lastName ? value.lastName : skipperAccount.lastName,
                mobile: value.phone ? value.phone : skipperAccount.mobile,
            };

        updateAccount(accountId, payload);
    }

    function setBillingFormConfig() {
        const skipperAccount = (account as SkipperFullInfo).account,
            formValues = {
                email: skipperAccount?.username,
                firstName: skipperAccount?.firstName,
                lastName: skipperAccount?.lastName,
                phone: skipperAccount?.mobile,
            },
            formConfig = profileFormConfig();

        setFormConfig(formConfig);
        setValue(formValues);
    }

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    return (
        <div className="col-12 col-md-6">
            {formConfig && (
                <Form
                    config={formConfig}
                    onValueStateChange={onValueStateChange}
                    value={value}
                    submitForm={() => submitBillingInfoForm()}
                    controlName={'changeProfileDetailsForm'}
                />
            )}

            <Loader type={LoaderType.Local} showLoader={isLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        account: accountSelector(state),
        isLoading: accountIsLoadingSelector(state),
    }),
    {
        updateAccount,
    }
)(ProfileData);
