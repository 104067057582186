import {combineEpics} from 'redux-observable';
import {
    accountEpic,
    changePasswordEpic,
    dictionaryDataEpic,
    loginEpic,
    reauthenticateEpic,
    renewAuthTokenEpic,
    authEpic,
} from 'marine-panel-common-web';
import paymentsPageEpic from './paymentsPageEpic';

export const rootEpic = combineEpics(
    dictionaryDataEpic,
    loginEpic,
    renewAuthTokenEpic,
    authEpic,
    reauthenticateEpic,
    changePasswordEpic,
    accountEpic,
    paymentsPageEpic
);
