import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'marine-panel-common-web';

export const newPasswordFormConfig: IFormConfig = {
    controlType: 'form',
    class: 'auth-form skipper-auth-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'reset_password_form',
            controlType: 'group',
            class: 'reset-password-form row',
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {accessors: [(value: any) => value.password, (value: any) => value.confirmPassword]},
                },
            ],
            controls: {
                password: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'auth.formControls.password',
                    isLabelHidden: false,
                    label: 'auth.formControls.newPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12 form-control',
                },
                confirmPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'auth.formControls.placeholderConfirmPassword',
                    label: 'auth.formControls.confirmPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12 form-control',
                },
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: '',
                    defaultContainerStyles: '',
                    defaultStyles: 'btn btn-primary ',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.setNewPassword',
                    isLabelHidden: true,
                },
            },
        },
    ],
};
