import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import {
    changePasswordStart,
    Form,
    FormControlChangeType,
    IFormConfig,
    passwordLoadingSelector,
    Translation,
    Loader,
    LoaderType,
} from 'marine-panel-common-web';
import {share, filter} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {changePasswordFormConfig} from './changePasswordFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';

interface IChangePasswordProps {
    readonly changePasswordStart: typeof changePasswordStart;
    readonly passwordLoading: boolean;
}

const ChangePassword: React.FC<IChangePasswordProps> = ({changePasswordStart, passwordLoading}) => {
    const [value, setValue] = useState<{[key: string]: any} | null>(null),
        [isFormValid, setIsFormValid] = useState<boolean>(false),
        [formConfig] = useState<IFormConfig | null>(changePasswordFormConfig()),
        [onValueStateChange$] = useState(() => new BehaviorSubject<any>(null)),
        [onValueStateChange$$] = useState(() => onValueStateChange$.pipe(share())),
        subscriptions: Subscription[] = [];

    function onValueStateChange(controlName: string, value: any, changeType: FormControlChangeType) {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    }

    function changePassword() {
        if (value !== null) {
            changePasswordStart(value.currentPassword, value.newPassword);
        }
    }

    function onFormValidityChange(controlName: string, isValid: boolean) {
        setIsFormValid(isValid);
    }

    function discardPasswordValue() {
        setValue(null);
    }

    useEffect(() => {
        subscriptions.push(
            onValueStateChange$$
                .pipe(
                    filter((data: {controlName?: string; value?: {[name: string]: string}; changeType?: FormControlChangeType}) => {
                        return data && data?.changeType === FormControlChangeType.User;
                    })
                )
                .subscribe((data) => {
                    if (data.controlName && data.value) {
                        setValue(data.value);
                    }
                })
        );
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    return (
        <div>
            {formConfig && (
                <Form
                    config={formConfig}
                    onValueStateChange={onValueStateChange}
                    value={value}
                    controlName={'profileForm'}
                    onValidationStateChange={onFormValidityChange}
                />
            )}

            <div className={styles.passwordRequirements}>
                <p>
                    <Translation text="settings.changePassword.passwordRequirements.title" />
                </p>
                <ul>
                    <li>
                        <Translation text="settings.changePassword.passwordRequirements.length" />
                    </li>
                    <li>
                        <Translation text="settings.changePassword.passwordRequirements.uppercase" />
                    </li>
                    <li>
                        <Translation text="settings.changePassword.passwordRequirements.characters" />
                    </li>
                </ul>
            </div>

            <div className={styles.btnWrapper}>
                <button
                    className="btn btn-underline btn-underline-primary discard-changes-button"
                    disabled={!value}
                    onClick={() => discardPasswordValue()}>
                    <Translation text="buttons.discard" />
                </button>
                <button className="btn btn-create change-password-button" disabled={!isFormValid} onClick={() => changePassword()}>
                    <Translation text="buttons.saveChanges" />
                </button>
            </div>

            <Loader type={LoaderType.Local} showLoader={passwordLoading} />
        </div>
    );
};

export default connect(
    (state: RootState) => ({
        passwordLoading: passwordLoadingSelector(state),
    }),
    {changePasswordStart}
)(ChangePassword);
