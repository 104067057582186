import classnames from 'classnames';
import {
    initLogout,
    menuCollapsedSelector,
    menuHiddenSelector,
    NotFound,
    Sidebar,
    Toast,
    Translation,
    changeMenuHidden,
} from 'marine-panel-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import Profile from '../Profile';
import menu from './menu-items';
import subMenu from './sub-menu-items';
import themeConfig from './themeConfig';
import Payments from '../Payments';
import {NavItem, NavLink} from 'reactstrap';
import {Menu} from 'react-feather';

interface IConnectedPanelHostProps {
    readonly initLogout: typeof initLogout;
    readonly changeMenuHidden: typeof changeMenuHidden;
    readonly menuHidden: boolean;
    readonly menuCollapsed: boolean;
}

interface IExternalPanelHostProps {
    envData: any;
    fullWidthLayout?: any;
}

interface IPanelHostProps extends IConnectedPanelHostProps, IExternalPanelHostProps {}

interface IPanelHostState {
    width: number;
}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            width: window.innerWidth,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        if (this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        } else {
            this.props.changeMenuHidden(false);
        }

        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps: Readonly<IPanelHostProps>, prevState: Readonly<IPanelHostState>, snapshot?: any) {
        if (this.state.width !== prevState.width && this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        }

        if (this.state.width !== prevState.width && this.state.width > 768) {
            this.props.changeMenuHidden(false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const isHidden = this.props.menuHidden;
        const menuCollapsed = this.props.menuCollapsed;
        const navbarWrapperClasses = {
            floating: 'navbar-floating',
            sticky: 'navbar-sticky',
            static: 'navbar-static',
            hidden: 'navbar-hidden',
        };
        const navbarType = 'floating';
        const windowWidth = window.innerWidth;

        return (
            <div className={`${themeConfig.layout.skin}-layout`}>
                <h1 className="sr-only">
                    <Translation text="app.title" />
                </h1>
                <div
                    className={classnames(`wrapper vertical-layout ${navbarWrapperClasses[navbarType] || 'navbar-floating'}`, {
                        'vertical-menu-modern': windowWidth >= 768,
                        'menu-collapsed': (menuCollapsed && !isHidden) || (windowWidth <= 768 && !isHidden),
                        'menu-expanded': !menuCollapsed && windowWidth > 768,
                        'menu-hide': isHidden || windowWidth <= 768,
                        // 'vertical-overlay-menu': windowWidth < 768,
                    })}
                    {...(isHidden || windowWidth <= 768 ? {'data-col': '1-column'} : {})}>
                    <nav className="header-navbar d-md-none d-sm-flex">
                        <div className="navbar-container d-flex justify-content-between align-items-center">
                            <img className="horizontal-logo" src={themeConfig.app.appMobileImage} alt="" />
                            <ul className="bookmark-wrapper justify-content-end">
                                <NavItem className="mobile-menu me-auto">
                                    <NavLink
                                        className="nav-menu-main menu-toggle is-active"
                                        onClick={() => this.props.changeMenuHidden(!isHidden)}>
                                        <Menu className="ficon" color="#000" />
                                    </NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </nav>
                    {!isHidden ? (
                        <Sidebar
                            skin={themeConfig.layout.skin}
                            logoImage={themeConfig.app.appLogoImage}
                            menuData={[menu(), subMenu(this.onLogout)]}
                            menuCollapsed={menuCollapsed}
                            toggleMobileMenu={() => this.props.changeMenuHidden(!isHidden)}
                            currentActiveItem={null}
                        />
                    ) : null}
                    <main className="panel-wrapper">
                        <div
                            className={classnames('sidenav-overlay d-md-none d-sm-flex', {
                                show: !isHidden,
                            })}
                            onClick={() => this.props.changeMenuHidden(!isHidden)}></div>

                        <Routes>
                            <Route path="/profile" element={<Profile />} key="profile" />
                            <Route path="/payments" element={<Payments />} key="payments" />
                            <Route path="*" element={<NotFound />} key="not-found" />
                        </Routes>
                    </main>
                </div>
                <Toast />
            </div>
        );
    }

    private onLogout = () => {
        this.props.initLogout();
        this.alertManager?.logoutSuccess();
        return <Navigate to="/" />;
    };

    private updateDimensions = () => {
        this.setState({width: window.innerWidth});
    };
}

export default connect(
    (state: RootState) => ({
        menuCollapsed: menuCollapsedSelector(state),
        menuHidden: menuHiddenSelector(state),
    }),
    {
        initLogout,
        changeMenuHidden,
    }
)(PanelHost);
