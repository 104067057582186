import React, {useEffect} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {
    DateComponent,
    IModelApiResponseViewObject,
    IReservationPayment,
    Loader,
    LoaderType,
    Pagination,
    Price,
    Translation,
} from 'marine-panel-common-web';
import {Card, CardBody, CardHeader, Table} from 'reactstrap';
import LayoutWrapper from '../LayoutWrapper';
import {changePaymentsPagePagination, fetchPayments, resetToInitialPaymentsPageState} from '../../store/reducers/paymentsPageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {isPaymentsPageLoadingSelector, paymentsPageMetadataSelector, paymentsSelector} from '../../store/selectors/paymentsPageSelectors';

interface IConnectedPaymentsProps {
    readonly payments: IReservationPayment[] | null;
    readonly isLoading: boolean;
    readonly paymentsPageMetadata: IModelApiResponseViewObject | null;
    readonly fetchPayments: typeof fetchPayments;
    readonly changePaymentsPagePagination: typeof changePaymentsPagePagination;
    readonly resetToInitialPaymentsPageState: typeof resetToInitialPaymentsPageState;
}

interface IPaymentsProps extends IConnectedPaymentsProps, WithTranslation {}

const Payments: React.FC<IPaymentsProps> = ({
    payments,
    isLoading,
    paymentsPageMetadata,
    fetchPayments,
    changePaymentsPagePagination,
    resetToInitialPaymentsPageState,
}) => {
    useEffect(() => {
        fetchPayments();

        return () => {
            resetToInitialPaymentsPageState();
        };
    }, [fetchPayments]);

    return (
        <LayoutWrapper>
            <Card className="transactions-history">
                <CardHeader>
                    <h2 className="section-subtitle">
                        <Translation text="payments.title" />
                    </h2>
                </CardHeader>
                <CardBody>
                    <Table responsive className="payments-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="payments.table.name" />
                                </th>
                                <th>
                                    <Translation text="payments.table.price" />
                                </th>
                                <th>
                                    <Translation text="payments.table.date" />
                                </th>
                                {/*<th />*/}
                            </tr>
                        </thead>
                        <tbody>{renderPaymentsTable()}</tbody>
                    </Table>

                    <div>
                        <Pagination listMetadata={paymentsPageMetadata} changePage={changePaymentsPagePagination} itemsPerPage={10} />
                    </div>
                    <Loader type={LoaderType.Local} showLoader={isLoading} />
                </CardBody>
            </Card>
        </LayoutWrapper>
    );

    function renderPaymentsTable() {
        if (null === payments || !payments.length) {
            return (
                <tr>
                    <td>
                        <Translation text="payments.table.noData" />
                    </td>
                </tr>
            );
        }

        return payments.map((item: IReservationPayment, index: number) => {
            return (
                <tr key={`article_${index}`}>
                    <td>{item.berth.name}</td>
                    <td>
                        <Price price={item.price} />
                    </td>
                    <td>
                        <DateComponent date={item.paidAt} format={'DD/MM/YYYY'} />
                    </td>
                    {/*<td>*/}
                    {/*</td>*/}
                </tr>
            );
        });
    }
};

export default connect(
    (state: RootState) => ({
        payments: paymentsSelector(state),
        isLoading: isPaymentsPageLoadingSelector(state),
        paymentsPageMetadata: paymentsPageMetadataSelector(state),
    }),
    {
        fetchPayments,
        resetToInitialPaymentsPageState,
        changePaymentsPagePagination,
    }
)(withTranslation()(Payments));
