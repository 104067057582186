import {AccessibilityMenu, Tabs, Translation} from 'marine-panel-common-web';
import React, {useState} from 'react';
import {Card, CardBody} from 'reactstrap';
import LayoutWrapper from '../LayoutWrapper';
import ChangePassword from './ChangePassword';
import ProfileData from './ProfileData';
import PrivacyPolicyCard from './PrivacyPolicyCard';

interface IProfileProps {}

const Profile: React.FC<IProfileProps> = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const selectTab = (tabNumber: number) => {
        setSelectedTab(tabNumber);
    };

    return (
        <LayoutWrapper>
            <h2 className="section-title">
                <Translation text="settings.title" />
            </h2>
            <Card className="payment-details-card">
                <CardBody>
                    <Tabs chosenTab={selectedTab} selectedTab={selectTab}>
                        <div className="tab-headers">
                            <span>
                                <Translation text={'profile.changeProfile.title'} />
                            </span>
                            <span>
                                <Translation text={'profile.changePassword.title'} />
                            </span>
                        </div>
                        <div className="tabs-content">
                            <div>
                                <ProfileData />
                            </div>
                            <div>
                                <ChangePassword />
                            </div>
                        </div>
                    </Tabs>
                </CardBody>
            </Card>
            <h2 className="section-title">
                <Translation text="settings.privacyPolicyTitle" />
            </h2>
            <PrivacyPolicyCard />
            <AccessibilityMenu />
        </LayoutWrapper>
    );
};

export default Profile;
