import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {RootState} from '../reducers';
import {
    addAlert,
    AlertType,
    ApiError,
    authTokenSelector,
    flattenObj,
    getMetadataDetails,
    handleApiError,
    IModelApiResponseViewObject,
    IRawRestQueryParams,
} from 'marine-panel-common-web';
import {
    changeIsPaymentsPageLoading,
    changePayments,
    changePaymentsPagePagination,
    fetchPayments,
    setPaymentsPageError,
    setPaymentsPageMetadata,
} from '../reducers/paymentsPageSlice';
import {getPaymentsAPI} from '../../api/getPayments';
import {paymentsPagePaginationSelector} from '../selectors/paymentsPageSelectors';

const fetchPaymentsEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return getPayments(action$, state$, fetchPayments);
};

const changePaymentsPaginationEpic: Epic = (action$: Observable<any>, state$: StateObservable<RootState>) => {
    return getPayments(action$, state$, changePaymentsPagePagination);
};

const getPayments = (action$: Observable<any>, state$: StateObservable<RootState>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = paymentsPagePaginationSelector(state$.value),
                params = paginationParams ? flattenObj(paginationParams) : null;

            return getPaymentsList(authToken, params);
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

export const getPaymentsList = (authToken: string, params?: IRawRestQueryParams | null) => {
    return getPaymentsAPI(authToken, params).pipe(
        switchMap((resp: any) => {
            const articleList = resp['hydra:member'],
                metadata = getMetadataDetails(resp['hydra:view']),
                actions = successActions(articleList, metadata);
            return of(...actions);
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

const errorActions = (error: ApiError) => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [addAlert(errorObj), changeIsPaymentsPageLoading(false), setPaymentsPageError(errorObj.message)];
};

const successActions = (payments: any[], metadata: IModelApiResponseViewObject | null): any[] => {
    return [changePayments(payments), setPaymentsPageMetadata(metadata), changeIsPaymentsPageLoading(false)];
};

const paymentsPageEpic = combineEpics(fetchPaymentsEpic, changePaymentsPaginationEpic);

export default paymentsPageEpic;
