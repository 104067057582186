import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IPaymentsState} from '../reducers/paymentsPageSlice';

export const selectPaymentsPage = (state: RootState) => {
    return state.paymentsPage;
};

export const paymentsSelector = createSelector([selectPaymentsPage], (state: IPaymentsState) => state.payments);

export const paymentsPagePaginationSelector = createSelector([selectPaymentsPage], (state: IPaymentsState) => state.pagination);

export const paymentsPageMetadataSelector = createSelector([selectPaymentsPage], (state: IPaymentsState) => state.paymentsMetadata);

export const isPaymentsPageLoadingSelector = createSelector([selectPaymentsPage], (state: IPaymentsState) => state.isLoading);

export const isPaymentsPageInitializedSelector = createSelector([selectPaymentsPage], (state: IPaymentsState) => state.isInitialized);

export const paymentsPageErrorSelector = createSelector([selectPaymentsPage], (state: IPaymentsState) => state.error);
