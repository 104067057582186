import {IRawRestQueryParams, marineHubAPI, RestQueryParams} from 'marine-panel-common-web';
import {Observable} from 'rxjs';

export function getPaymentsAPI(authToken: string | null, params?: IRawRestQueryParams | null): Observable<any> {
    const queryParams = params ? new RestQueryParams(params) : new RestQueryParams(),
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    return marineHubAPI.get('api/reservations/payments_listing', queryParams, headers);
}
