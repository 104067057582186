import {FormControlType, IFormConfig, InputType, ValidationRules} from 'marine-panel-common-web';

export const changePasswordFormConfig = (): IFormConfig => ({
    controlType: 'form',
    class: 'default-form profile',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'current_password',
            controlType: 'group',
            class: 'row',
            controls: {
                currentPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 8}},
                    ],
                    placeholder: 'settings.changePassword.form.placeholders.currentPassword',
                    isLabelHidden: false,
                    label: 'settings.changePassword.form.labels.currentPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6 form-control',
                    autocomplete: 'off',
                },
            },
        },
        {
            key: 'new_password',
            controlType: 'group',
            class: 'row',
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {accessors: [(value: any) => value.password, (value: any) => value.newPassword]},
                },
            ],
            controls: {
                password: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 8}},
                    ],
                    placeholder: 'settings.changePassword.form.placeholders.newPassword',
                    isLabelHidden: false,
                    label: 'settings.changePassword.form.labels.newPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6 form-control',
                    autocomplete: 'off',
                },
                newPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 8}},
                    ],
                    placeholder: 'settings.changePassword.form.placeholders.confirmPassword',
                    isLabelHidden: false,
                    label: 'settings.changePassword.form.labels.confirmPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6 form-control',
                    autocomplete: 'off',
                },
            },
        },
    ],
});
