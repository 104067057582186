import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'marine-panel-common-web';

export const registrationFormConfig: IFormConfig = {
    controlType: 'form',
    class: 'auth-form skipper-auth-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'registration_email',
            controlType: 'group',
            class: 'row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}},
                    ],
                    placeholder: 'auth.formControls.placeholders.email',
                    isLabelHidden: false,
                    label: 'auth.formControls.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            key: 'registration_password',
            controlType: 'group',
            class: 'row',
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {accessors: [(value: any) => value.plainPassword, (value: any) => value.confirmPassword]},
                },
            ],
            controls: {
                plainPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'auth.formControls.placeholders.password',
                    isLabelHidden: false,
                    label: 'auth.formControls.password',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12 form-control',
                },
                confirmPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'auth.formControls.placeholders.password',
                    isLabelHidden: false,
                    label: 'auth.formControls.confirmPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12 form-control',
                },
            },
        },
        {
            controlType: 'group',
            key: 'registration_agreements',
            class: 'row agreement-container',
            controls: {
                registrationTermsAgreement: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [],
                    placeholder: '',
                    label: '',
                    isLabelHidden: true,
                    isCheckboxLabelRaw: true,
                    checkboxLabel: `By submitting registration to MarineHub Platform you agree to our <a href="${process.env.REACT_APP_PRIVACY_POLICY_URL}" target="_blank"> Privacy Policy</a>.`,
                    hostClass: 'col-xl-12 checkbox-container checkbox-hidden',
                },
            },
        },
        {
            controlType: 'group',
            key: 'registration_submit',
            class: 'row register-form',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    hostClass: '',
                    inputStyles: 'btn-primary',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.register',
                },
            },
        },
    ],
};
