import {ISideMenuGroup, ISideMenuHeader, ISideMenuItem, IconSize} from 'marine-panel-common-web';
import {DollarSign, User} from 'react-feather';
import React from 'react';

const menu = (): (ISideMenuGroup | ISideMenuItem | ISideMenuHeader)[] => [
    {
        id: 'profile',
        title: 'menuItems.profile',
        icon: <User size={IconSize.MainMenuItems} />,
        navLink: '/panel/profile',
        navLinkClassname: 'profile-link',
    },
    {
        id: 'payments',
        title: 'menuItems.payments',
        icon: <DollarSign size={IconSize.MainMenuItems} />,
        navLink: '/panel/payments',
        navLinkClassname: 'payments-link',
    },
];
export default menu;
