import {Translation} from 'marine-panel-common-web';
import React from 'react';
import {Card, CardBody} from 'reactstrap';

const PrivacyPolicyCard: React.FC = () => {
    return (
        <Card className="privacy-policy-card">
            <CardBody>
                <div className="information-text">
                    <Translation text="settings.privacyPolicyMessage" />{' '}
                    <a href={`${process.env.REACT_APP_PRIVACY_POLICY_URL}`} target="_blank" rel="noreferrer">
                        {' '}
                        <Translation text="settings.readLink" />
                    </a>
                </div>
            </CardBody>
        </Card>
    );
};

export default PrivacyPolicyCard;
